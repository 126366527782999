.error-image {
    height: 70vh;
    border-radius: 20px;
    border: 1px solid #3b71ca;
}

@media screen and (max-width: 480px) {
    .error-image {
        width: 80vw;
        height: 100vw;
    }
}