$bg: #FFF;
$white: #fff;
$black: #319AC7;
$hoverColor: #0E4366;
$getInColor: #1B2231; // New color for "Get in"

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, .076, 1)) {
    transition: $property $duration $ease;
}

* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: Montserrat;

    &.learn-more {
        width: 20rem;
        height: 5.1rem;

        .circle {
            @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
            position: relative;
            display: block;
            margin: 0;
            width: 3rem;
            height: 3rem;
            background: $black;
            border-radius: 1.625rem;

            .icon {
                @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                background: $white;

                &.arrow {
                    background: $white;
                    @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                    left: 0.5rem;
                    width: 1.5rem;
                    height: 0.2rem;
                    background: none;

                    &::before {
                        position: absolute;
                        content: '';
                        top: -0.375rem;
                        right: 0rem;
                        width: 0.9375rem;
                        height: 0.9375rem;
                        border-top: 0.2rem solid #fff;
                        border-right: 0.2rem solid #fff;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .button-text {
            @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0.75rem 0;
            margin: 0 0 0 3rem; // Increased margin for more space
            color: $black;
            font-family: Montserrat;
            font-size: 2.375rem;
            font-style: normal;
            font-weight: 600;

            line-height: 1.6;
            text-align: center;
            text-transform: none;

            .getin {
                color: $getInColor; // Set default color for "Get in"
            }
        }

    }

    &:hover {
        .circle {
            background-color: $hoverColor;
            width: calc(100% + 2rem); // Increased width for more space

            .icon {
                &.arrow {
                    background: $white;
                    transform: translate(1rem, 0);
                }
            }
        }

        .button-text {
            color: $white;

            .getin {
                color: $white; // Set "Get in" color to #fff on hover
            }
        }



    }
}