
$bp-md: 600px;
$bp-lg: 900px;

:root {
    --d: 700ms;
    --e: cubic-bezier(0.19, 1, 0.22, 1);
    
}


.card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 1rem;
    width: 100%;
    text-align: center;
    color: whitesmoke;
    background-color: whitesmoke;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1),
        0 2px 2px rgba(0, 0, 0, 0.1),
        0 4px 4px rgba(0, 0, 0, 0.1),
        0 8px 8px rgba(0, 0, 0, 0.1),
        0 16px 16px rgba(0, 0, 0, 0.1);

    @media (min-width: $bp-lg) {
        height: 250px;
    }
         @media (max-width: $bp-lg) {
             height: 200px;
         }
         @media (max-width: $bp-md) {
             height: 175px;
         }


    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 110%;
        background-size: cover;
        background-position: 0 0;
        transition: transform calc(var(--d) * 1.5) var(--e);
        pointer-events: none;
        // background-image: url('https://www.wildnatureimages.com/images/640/070620-014-The-Tetons.jpg');
        background-image: url('');
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200%;
        pointer-events: none;
        // background-image: linear-gradient(to bottom,
        //         hsla(0, 0%, 0%, 0) 0%,
        //         hsla(0, 0%, 0%, 0.009) 11.7%,
        //         hsla(0, 0%, 0%, 0.034) 22.1%,
        //         hsla(0, 0%, 0%, 0.072) 31.2%,
        //         hsla(0, 0%, 0%, 0.123) 39.4%,
        //         hsla(0, 0%, 0%, 0.182) 46.6%,
        //         hsla(0, 0%, 0%, 0.249) 53.1%,
        //         hsla(0, 0%, 0%, 0.320) 58.9%,
        //         hsla(0, 0%, 0%, 0.394) 64.3%,
        //         hsla(0, 0%, 0%, 0.468) 69.3%,

        //         hsla(0, 0%, 0%, 0.540) 74.1%,
        //         hsla(0, 0%, 0%, 0.607) 78.8%,
        //         hsla(0, 0%, 0%, 0.668) 83.6%,
        //         hsla(0, 0%, 0%, 0.721) 88.7%,
        //         hsla(0, 0%, 0%, 0.762) 94.1%,
        //         hsla(0, 0%, 0%, 0.790) 100%);

        background-image: white;
        transform: translateY(-50%);
        transition: transform calc(var(--d) * 2) var(--e);
    }
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    transition: transform var(--d) var(--e);
    z-index: 1;

    >*+* {
        margin-top: 1rem;
    }
}

.title {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.2;
}

.copy {
    font-family: var(--font-serif);
    font-size: 1.125rem;
    font-style: italic;
    line-height: 1.35;
}

.btn {
    cursor: pointer;
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem;
    font-size: 0.65rem;
    font-weight: bold;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    color: white;
    background-color: black;
    border: none;

    &:hover {
        background-color: lighten(black, 5%);
    }

    &:focus {
        outline: 1px dashed yellow;
        outline-offset: 3px;
    }
}

@media (hover: hover) and (min-width: $bp-md) {
    .card:after {
        transform: translateY(0);
    }

    .content {
        transform: translateY(calc(100% - 4.5rem));

        >*:not(.title) {
            opacity: 0;
            transform: translateY(1rem);
            transition:
                transform var(--d) var(--e),
                opacity var(--d) var(--e);
        }
    }

    .card:hover,
    .card:focus-within {
        align-items: center;

        &:before {
            transform: translateY(-4%);
        }

        &:after {
            transform: translateY(-50%);
        }

        .content {
            transform: translateY(0);

            >*:not(.title) {
                opacity: 1;
                transform: translateY(0);
                transition-delay: calc(var(--d) / 8);
            }
        }
    }

    .card:focus-within {

        &:before,
        &:after,
        .content,
        .content>*:not(.title) {
            transition-duration: 0s;
        }
    }
}