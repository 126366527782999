.btn-shine {
    background-image: linear-gradient(140deg, #0E4366, #62C4EA, #0E4366, #62C4EA, #0E4366, #62C4EA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 500%;
    animation: gradient 20s linear infinite;
}

#whyoctathorn {
    margin-top: 50px; /* Adjust the value as needed for spacing */
  }

@keyframes gradient {
    from {
        background-position: 0;
    }

    to {
        background-position: 500%;
    }
}