/* Custom styles for carousel dots */
.carousel .slide {
    /* styles for each slide if needed */
  }
  
  .carousel .control-dots {
    bottom: 10px; /* Positioning of dots */
  }
  
  .carousel .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #b0c4de; /* Color for inactive dots */
    margin: 0 5px;
    cursor: pointer;
  }
  
  .carousel .dot.selected {
    background-color: #003366; /* Color for active dot */
  }
  