

.bttn {
    border: none;

}

.bttn:before,
.bttn:after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    background: #0E4366;
    transition: all 0.3s ease;
}

.bttn:before {
    height: 0%;
    width: 2px;
}

.bttn:after {
    width: 0%;
    height: 2px;
}

.bttn:hover {
    background: transparent;
    box-shadow: none;
}

.bttn:hover:before {
    height: 100%;
}

.bttn:hover:after {
    width: 100%;
}
 





.btt {
    border: transparent;
}


.btt:before,
.btt:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: #0E4366;
    transition: all 0.3s ease;
}

.btt:before {
    height: 0%;
    width: 2px;
}

.btt:after {
    width: 0%;
    height: 2px;
}

.btt:hover {
    background: transparent;
    box-shadow: none;
}

.btt:hover:before {
    height: 100%;
}

.btt:hover:after {
    width: 100%;
}

